/* eslint-disable @typescript-eslint/no-unused-vars */
import { DimInternalDataPlan, Organization } from "@prisma/client"
import { DailyUsage } from "~/common/interfaces/DailyUsage"
import { UsageType } from "~/interfaces/UsageType"

class OrganizationsService {
  async create(organizationData: Partial<Organization>) {
    const { data } = await useFetch<{ body: Organization }>("/api/organizations", {
      method: "POST",
      body: organizationData
    })
    if (!data.value) {
      throw createError("Organization not created")
    }

    return data.value.body
  }

  async deactivate(organizationId: number) {
    const { data } = await useFetch(`/api/organizations/deactivate`, {
      method: "PUT",
      body: {
        organizationId
      }
    })
    return data.value
  }

  async delete(organizations: Organization[]) {
    const { data } = useFetch("/api/organizations/delete", {
      method: "DELETE",
      body: organizations
    })
    const body = data.value
    return body
  }

  // NOTE Do we need this hardcore delete method with its own endpoint or do we stick with the soft delete above
  async deleteOrganization(organizationId: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = await useFetch<string>(`/api/organizations/${organizationId}`, {
      method: "DELETE"
    })
    return data.value
  }

  async disable(organizationId: number) {
    const { data } = await useFetch(`/api/organizations/disable`, {
      method: "PUT",
      body: { organizationId }
    })
    return data.value
  }

  async enable(organizationId: number) {
    const { data } = await useFetch(`/api/organizations/enable`, {
      method: "PUT",
      body: { organizationId }
    })
    return data.value
  }

  async findByName(name: string | undefined) {
    try {
      const encodedName = encodeURIComponent(name || "")
      const data = await $fetch<Organization>(`/api/organizations/name/${encodedName}`, {
        method: "GET"
      })

      return data
    } catch (error) {
      return null
    }
  }

  // async disableSims(organizationId: number) {
  //   const { data } = await useFetch(`/api/organizations/${organizationId}/connections/disable`, {
  //     method: "PUT"
  //   })
  //   return data.value
  // }

  async getAll() {
    const { data } = await useFetch<{
      body: Organization[]
    }>("/api/organizations/all", {
      method: "GET"
    })
    if (!data.value) {
      throw createError("Organizations not found")
    }
    const organizations = data.value.body
    const mappedData = organizations.map((organization: Organization) => {
      return {
        name: organization.name,
        organizationId: organization.organizationId
      }
    })

    return mappedData
  }

  async getById(organizationId: number) {
    const data = await $fetch<Organization>(`/api/organizations/${organizationId}`, {
      method: "GET"
    })
    return data
  }

  async getCurrencies() {
    const { data } = await useFetch(`/api/currencies/`, {
      method: "GET"
    })
    return data.value?.body
  }

  async getDailyUsage(billingCyclesBack: number, organizationId: number, usageType: UsageType) {
    const data = await $fetch<DailyUsage[]>(`/api/organizations/${organizationId}/usage`, {
      method: "GET",
      params: {
        billingCyclesBack,
        usageType
      }
    })
    return data
  }

  async getDataAllowanceTotal(organizationId: number) {
    const data = await $fetch(`/api/organizations/${organizationId}/allowance`, {
      method: "GET"
    })
    return data
  }

  async getOrganizationPlans(organizationId: number) {
    const data = await $fetch<DimInternalDataPlan[]>(`/api/organizations/${organizationId}/plans`, {
      method: "GET"
    })
    const plans = data
    return plans
  }

  async getPlanCounts(organizationId: number) {
    const data = await $fetch(`/api/organizations/${organizationId}/plans/count`, {
      method: "GET"
    })
    return data
  }

  async getSimCount(organizationId: number) {
    const data = await $fetch(`/api/organizations/${organizationId}/connections/count`, {
      method: "GET"
    })
    return data
  }

  async getUsageTotals(organizationId: number, billingCyclesBack: number, currentDateId: number) {
    const data = await $fetch(`/api/organizations/${organizationId}/usage/totals`, {
      method: "GET",
      params: {
        billingCyclesBack: billingCyclesBack ? billingCyclesBack : 0,
        currentDateId: currentDateId
      }
    })
    return data
  }

  async getUsers(organizationId: number) {
    const data = await $fetch(`/api/organizations/${organizationId}/users`, {
      method: "GET"
    })
    return data
  }

  async getAllUsageByPlan(organizationId: number, billingCyclesBack: number) {
    const data = await $fetch(`/api/organizations/${organizationId}/plans/usage`, {
      method: "GET",
      params: {
        billingCyclesBack: billingCyclesBack ? billingCyclesBack : 0
      }
    })
    return data
  }

  async reactivate(organizationId: number) {
    const response = await useFetch(`/api/organizations/reactivate`, {
      method: "PUT",
      body: {
        organizationId
      }
    })
    return response
  }

  async terminateSims(organizationId: number) {
    const response = await useFetch(`/api/organizations/${organizationId}/connections/terminate`, {
      method: "PUT"
    })
    return response
  }

  async update(organizationId: number, formData: Partial<Organization>) {
    const data = await $fetch(`/api/organizations/${organizationId}`, {
      method: "PUT",
      body: formData
    })
    if (!data) {
      throw createError("There was an error.")
    }
    return data
  }
}

export const organizationsService = new OrganizationsService()
